import axios from "axios";
import {
  getUserStart,
  getUserSuccess,
  getUserFailure,
  updateMyInformationsStart,
  updateMyInformationsSuccess,
  updateMyInformationsFailure,
} from "./MyInformationsAction";
import notifySuccess from "../../components/notify/notifySuccess";
import notifyError from "../../components/notify/notifyError";

const url = "https://application.netfaraj-dz.com/api";

export const getMyInformations = async (dispatchUser) => {
  const objectID = JSON.parse(localStorage.getItem("user")).user._id;
  dispatchUser(getUserStart());
  try {
    const res = await axios.get(`${url}/user/find/` + objectID, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatchUser(getUserSuccess(res.data));
  } catch (error) {
    dispatchUser(getUserFailure());
  }
};

export const updateMyInformations = async (id, user, dispatchUser) => {
  dispatchUser(updateMyInformationsStart());
  try {
    const res = await axios.put(`${url}/user/` + id, user, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatchUser(updateMyInformationsSuccess(res.data));
    notifySuccess("The account details have been successfully updated.");
  } catch (error) {
    dispatchUser(updateMyInformationsFailure());
    notifyError("The account details could not be updated.");
  }
};

export const addMovieToWatchedMovies = async (movieId, user, dispatchUser) => {
  dispatchUser(updateMyInformationsStart());
  try {
    const res = await axios.put(`${url}/user/` + user._id + "/" + movieId, user, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    dispatchUser(updateMyInformationsSuccess(res.data));
  } catch (error) {
    dispatchUser(updateMyInformationsFailure());
  }
};
