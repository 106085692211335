import React, { useState } from "react";
import Header from "../../components/header/Header";
import BannerPages from "../../components/banners/BannerPages";
import { Container, Row, Col } from "react-bootstrap";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import multipleDevices from "../../assets/images/multipleDevices.png";

export default function FAQ() {
  const [faq, setfaq] = useState("1");

  return (
    <>
      <Header />
      <BannerPages pageName="Frequently Asked Questions" />
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-accordion iq-accordion-square">
                <div
                  className={`iq-accordion-block  1 ${
                    faq === "1" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("1");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      What is NetferajDZ?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "1" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                    NetferajDZ is a free streaming service that allows its users to watch movies on any device connected to the internet. The service is 100% free and incurs no costs. Watch as much as you want, whenever you want, and with no ads, all for free. You will always discover something new, as we add new movies every week.
                    </p>
                  </div>
                </div>
                <div
                  className={`iq-accordion-block 2  ${
                    faq === "2" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("2");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      What devices can I use to watch movies?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "2" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      Watch anywhere and anytime on any device with an internet connection.
                    </p>
                    <p className="iq-content-text">
                      You can watch movies on iPhone, iPad, mobile phones, Android tablets, Apple TV, Android TV, Chromecast, Samsung TV, LG, Chrome OS, MacOS, Windows PC, PS4, and PS5, and in the future, the app will also be available on other devices.
                    </p>
                    <p className="iq-content-text">
                      Thanks to its responsive design, the service can be streamed on any screen!
                    </p>
                    <div className=" text-center" style={{ paddingTop: 5 }}>
                      <img
                        src={multipleDevices}
                        width="500"
                        className="img-fluid d-block mx-auto mb-3"
                        alt="multipleDevices"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`iq-accordion-block 3 ${
                    faq === "3" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("3");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      What can I watch on NetferajDZ?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "3" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                    NetferajDZ offers a new viewing experience and brings to you, for the first time in one place, the most fascinating stories from Warner Bros., HBO, DC, Cartoon Network, and many others. Our service has an extraordinarily rich library with award-winning films, feature films, documentaries, anime, and much more.
                    </p>
                  </div>
                </div>
                <div
                  className={`iq-accordion-block 4  ${
                    faq === "4" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("4");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      How can I delete my account?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "4" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                    NetferajDZ is a flexible service, so there are no hidden clauses or obligations. You can easily cancel your account by contacting the service administrator, with no cancellation fees.
                    </p>
                    <p className="iq-content-text">
                      To contact them, you need to fill out the form on the{" "}
                      <Link to="/contact-us" style={{ fontWeight: "bold" }}>
                        Contact
                      </Link>{" "}
                      page, specifying your name, surname, phone number, email address, and the reason for canceling your user account. This helps us improve our service in the future.
                    </p>
                  </div>
                </div>
                <div
                  className={`iq-accordion-block 5  ${
                    faq === "5" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("5");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      What is the content rating system?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "5" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      The content rating system is a rating system for movies and TV shows designed to estimate their suitability for certain audiences based on their content.
                    </p>
                    <p className="iq-content-text">
                      The MPA (Motion Picture Association) rating system is an example of a content rating system used to help parents decide what media content is appropriate for their children.
                    </p>
                    <div>
                      <Card.Body>
                        <div
                          className="table-responsive"
                          style={{ paddingTop: 20 }}
                        >
                          <table
                            id="datatable"
                            className="table table-striped table-bordered"
                          >
                            <thead>
                              <tr>
                                <th style={{ textAlign: "center" }}>
                                  Rating Symbol
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  Description
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>G</td>
                                <td>
                                  General Audience - All ages are admitted.
                                </td>
                              </tr>
                              <tr>
                                <td>R</td>
                                <td>
                                  Restricted - Contains adult material, and children under 17 require accompanying parent or adult guardian.
                                </td>
                              </tr>
                              <tr>
                                <td>PG</td>
                                <td>
                                  Parental Guidance Suggested - Some material may not be suitable for children.
                                </td>
                              </tr>
                              <tr>
                                <td>PG-13</td>
                                <td>
                                  Parents Strongly Cautioned - Some material may be inappropriate for children under 13, so parents are urged to provide proper supervision.
                                </td>
                              </tr>
                              <tr>
                                <td>NC-13</td>
                                <td>
                                  Adults Only - Persons under 17 are not admitted to view this content.
                                </td>
                              </tr>
                              <tr>
                                <td>TV-MA</td>
                                <td>
                                  Mature Audiences Only - This program is specifically designed to be viewed by adults and may be unsuitable for children under 17.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </>
  );
}
