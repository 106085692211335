import React, { useState } from "react";
import notifyError from "../notify/notifyError";
import notifySuccess from "../notify/notifySuccess";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

const url = "https://application.netfaraj-dz.com/api";

export default function ContactForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendMessage = (e) => {
    e.preventDefault();
    if (firstName.length < 3) {
      notifyError("      The name must contain at least 3 characters.");
    } else if (lastName.length < 3) {
      notifyError("      The first name must contain at least 3 characters.");
    } else if (phoneNumber.length !== 10) {
      notifyError("      The phone number must contain exactly 10 digits.");
    } else if (emailAddress.length < 5) {
      notifyError("      The email address must contain at least 5 characters.");
    } else if (message.length < 15) {
      notifyError(
        "        To be able to send the message, it must contain at least 15 characters."
      );
    } else {
      axios.post(`${url}/user/contact/`, {
        firstName,
        lastName,
        phoneNumber,
        emailAddress,
        message,
      })
        .then((res) => {
          if (res.status === 200) {
            notifySuccess("            Your message has been sent.");
          }
        })
        .catch(() => notifyError("        Your message could not be sent."));
      const inputs = document.querySelectorAll(
        "#firstName, #lastName, #phoneNumber, #emailAddress, #message"
      );
      inputs.forEach((input) => {
        input.value = "";
      });
    }
  };

  return (
    <>
      <div className="text-center iq-title-box iq-box iq-title-default">
        <h2 className="iq-title">        Send a message</h2>
        <p className="iq-title-desc">
                    Fill out this form to get in touch with us
        </p>
      </div>
      <div className="wrapper">
        <section>
          <Container>
            <Row className="row align-items-center justify-content-center h-100">
              <Col lg="10">
                <div
                  className="sign-user_card"
                  style={{ backgroundColor: "#161414" }}
                >
                  <Row>
                    <Col lg="12" className="device-margin">
                      <div className="profile-from">
                        <Form>
                          <Row>
                            <Col md="6">
                              <Form.Group className="form-group">
                                <Form.Control
                                  type="text"
                                  className="form-control mb-0"
                                  id="firstName"
                                  placeholder="Nume"
                                  autoComplete="off"
                                  required
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="6">
                              <Form.Group className="form-group">
                                <Form.Control
                                  type="text"
                                  className="form-control mb-0"
                                  id="lastName"
                                  placeholder="Prenume"
                                  autoComplete="off"
                                  required
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="6">
                              <Form.Group className="form-group">
                                <Form.Control
                                  type="text"
                                  className="form-control mb-0"
                                  id="phoneNumber"
                                  placeholder="Telefon"
                                  autoComplete="off"
                                  required
                                  onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="6">
                              <Form.Group className="form-group">
                                <Form.Control
                                  type="email"
                                  className="form-control mb-0"
                                  id="emailAddress"
                                  placeholder="E-mail"
                                  autoComplete="off"
                                  required
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <div className="cfield">
                                <span className="wpcf7-form-control-wrap your-message">
                                  <textarea
                                    name="your-message"
                                    id="message"
                                    cols="40"
                                    rows="10"
                                    className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="Mesaj"
                                    onChange={(e) => {
                                      setMessage(e.target.value);
                                    }}
                                  ></textarea>
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Button
                            type="button"
                            variant="btn btn-primary"
                            onClick={sendMessage}
                            style={{
                              backgroundColor: "red",
                              borderColor: "red",
                            }}
                          >
                          Send
                          </Button>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <ToastContainer />
      </div>
    </>
  );
}
