import React from "react";
import Header from "../../components/header/Header";
import BannerPages from "../../components/banners/BannerPages";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import register from "../../assets/images/register.png";
import deleteAccount from "../../assets/images/deleteAccount.png";
import accessAccount from "../../assets/images/accessAccount.png";
import streaming from "../../assets/images/streaming.png";
import termsOfUse from "../../assets/images/termsOfUse.png";
import Footer from "../../components/footer/Footer";

export default function TermsOfUse() {
  return (
    <>
      <Header />
      <BannerPages pageName="Terms and Conditions" />
      <main id="main" className="site-main">
  <Container>
    <Row>
      <Col lg="12" sm="12">
        <div className="iq-privacy-policy">
          <div className="mb-3" style={{ fontSize: 18 }}>
            <blockquote className="wp-block-quote">
              <p>
              Netfaraj offers a free streaming service that allows its users to access entertainment content via the internet on televisions, computers, tablets, or other internet-connected devices.
              </p>
              <p>
                The use of this service is subject to these Terms of Use, and continued use of the service implies acceptance of all the terms and conditions presented on the site.
              </p>
              <cite>
                <Link to="#">Netfaraj Team</Link>
              </cite>
            </blockquote>
            <br />
          </div>
          <div className="mb-3 firstElelement">
            <h4 className="mb-3">1. Account Registration</h4>
            <p className="mb-2">
              Creating a user account and using our streaming service is completely free and incurs no costs. To create an account, a valid email address is required. To use the service, you need internet access and a device compatible with Netfaraj.
            </p>
            <p className="mb-2">
              If any of the terms and conditions in this section are violated, we reserve the right to delete your account permanently, including all associated information. Additionally, we do not reserve any right to view or update the various information associated with the user account.
            </p>
            <div style={{ marginRight: "50%" }}>
              <img
                src={register}
                width="220"
                className=" img-fluid d-block"
                alt="register"
              />
            </div>
          </div>
          <div className="mb-3 secondElelement">
            <h4 className="mb-3">2. Account Cancellation</h4>
            <p className="mb-2">
              Users cannot cancel their account themselves and must contact the service administrator.
            </p>
            <p className="mb-2">
              To contact the administrator, fill out the form on the{" "}
              <Link to="/contact-us" style={{ fontWeight: "bold" }}>
                Contact
              </Link>{" "}
              page, specifying your name, surname, phone number, email address, and the reason for wanting to delete your user account.
            </p>
            <div style={{ marginLeft: "65%" }}>
              <img
                src={deleteAccount}
                width="1000"
                className=" img-fluid d-block"
                alt="deleteAccount"
              />
            </div>
          </div>
          <div className="mb-3 firstElelement">
            <h4 className="mb-3">3. Passwords and Account Access</h4>
            <p className="mb-2">
              The user who has created the account is responsible for all activities within it.
            </p>
            <p className="mb-2">
              To maintain control over the account and prevent unauthorized access (including access to account viewing history), the Account Holder must keep control of access to the service and not disclose login details to anyone.
            </p>
            <div style={{ marginRight: "50%" }}>
              <img
                src={accessAccount}
                width="250"
                className=" img-fluid d-block"
                alt="accessAccount"
              />
            </div>
          </div>
          <div className="mb-3 secondElelement">
            <h4 className="mb-3">4. Netfaraj Service</h4>
            <p className="mb-2">
              You must be at least 18 years old to create an account. Minors may use the service only under the supervision of an adult. Any content accessed through the service is for personal and non-commercial use only and may not be distributed to others outside your household.
            </p>
            <p className="mb-2">
              The service and title collection are updated periodically. Additionally, we continuously test various aspects of our service, including the website, user interfaces, functionalities, and the availability of Netfaraj content.
            </p>
            <div style={{ marginLeft: "62%" }}>
              <img
                src={streaming}
                width="450"
                className=" img-fluid d-block"
                alt="multipleDevices"
              />
            </div>
          </div>
          <div className="mb-3">
            <h4 className="mb-3">
              5. Changes to the Terms of Use
            </h4>
            <p className="mb-2">
            Netfaraj may occasionally modify these Terms of Use. We will notify you at least 30 days before any changes take effect.
            </p>
            <div style={{ marginRight: "50%" }}>
              <img
                src={termsOfUse}
                width="280"
                className=" img-fluid d-block"
                alt="termsOfUse"
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
</main>

      <Footer />
    </>
  );
}
