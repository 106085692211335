import React from "react";
import Header from "../../components/header/Header";
import BannerPages from "../../components/banners/BannerPages";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../components/footer/Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <BannerPages pageName="Privacy Policy" />
<main id="main" className="site-main">
  <Container>
    <Row>
      <Col lg="12" sm="12">
        <div className="iq-privacy-policy">
          <div className="mb-3" style={{ fontSize: 18 }}>
            <blockquote className="wp-block-quote">
              <p>
                This Privacy Statement explains our practices, including your options regarding the collection, use, and disclosure of certain information, including personal information, in relation to the  Netfaraj service.
              </p>
              <p>
                For specific questions regarding this Privacy Statement or about the use of personal information, cookies, or other similar technologies, please contact the Data Protection Officer via email at amineksouri213@gmail.com.
              </p>
            </blockquote>
            <br />
          </div>
          <div className="mb-3">
            <h4 className="mb-3">1. Information Collection</h4>
            <p className="mb-2">
              We collect and store information about you, such as:
            </p>
            <ul>
              <li>
                Name, email address, phone number, and other identifiers you may use. We collect this information through various means, including when you enter it to use the service or when you interact with customer service;
              </li>
              <li>
                Your activity on the Netfaraj service, such as selections of titles for movies watched;
              </li>
              <li>
                Details about your interactions with customer service, such as the date, time, and reason for contact, and if you called us, the phone number and recordings of the calls;
              </li>
              <li>
                Device IDs or other unique identifiers, including those of your network devices connected to your Wi-Fi network.
              </li>
            </ul>
            <br />
          </div>
          <div className="mb-3">
            <h4 className="mb-3">2. Use of Information</h4>
            <p className="mb-2">
              We use information to provide, analyze, administer, improve, and personalize our services and marketing efforts, to manage user recommendations, and to communicate with you about these or other topics.
            </p>
            <p className="mb-2">
              This information may also be used to ensure the security of our systems, to prevent fraud, and to help protect the security of Netfaraj accounts, as well as to prevent, detect, and investigate potentially prohibited or illegal activities, including fraud, and to enforce the terms of service.
            </p>
            <br />
          </div>
          <div className="mb-3">
            <h4 className="mb-3">3. Disclosure of Information</h4>
            <p className="mb-2">
              Your information is disclosed for certain purposes and to third parties in the following cases:
            </p>
            <ul>
              <li>
              Netfaraj Group – Your information is disclosed within the Netfaraj group for:
                <ul>
                  <li>Enabling access to our services;</li>
                  <li>Providing customer support;</li>
                  <li>Making decisions regarding service improvements and content development.</li>
                </ul>
              </li>
              <li>
                Business Transfers – In the event of any reorganization, restructuring, merger, sale, or other transfer of assets, we will transfer information, including personal information, provided that the receiving party agrees to keep it in accordance with our Privacy Statement.
              </li>
            </ul>
            <br />
          </div>
          <div className="mb-3">
            <h4 className="mb-3">4. Your Information and Rights</h4>
            <p className="mb-2">
              You can request access to your personal information or correct or update inaccurate or outdated personal information we hold about you. You can also request the deletion of personal information we hold about you.
            </p>
            <p>
              You can object to the processing of your personal information, request restrictions on the processing of your personal information, or request the portability of your personal information. If we have collected and processed your personal information with your consent, you may withdraw your consent at any time.
            </p>
            <br />
          </div>
          <div className="mb-0">
            <h4 className="mb-3">5. Security</h4>
            <p className="mb-2">
              We take reasonable administrative, logical, physical, and managerial measures to protect your personal information against loss, theft, and unauthorized access, use, or alteration. These measures are designed to provide a level of security appropriate to the risks associated with processing personal information.
            </p>
            <p className="mb-2">
              This Privacy Statement will be updated periodically to reflect changes in legal, regulatory, or operational requirements. We will notify you of such changes (including the effective date) as required by law. Continued use of the Netfaraj service following these updates will constitute your acknowledgment and acceptance of such changes.
            </p>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
</main>

      <Footer />
    </>
  );
}
