import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

export default function DropdownGenre({ setGenre }) {
  const handleSelect = (eventKey) => {
    setGenre(eventKey);
  };

  return (
    <div style={{ paddingLeft: 100 }}>
      <Dropdown className="genres-box">
        <DropdownButton
          variant="secondary"
          onSelect={handleSelect}
          title="Gen film"
        >
<div className="three-column">
  <Dropdown.Item eventKey="Action">Action</Dropdown.Item>
  <Dropdown.Item eventKey="Animation">Animation</Dropdown.Item>
  <Dropdown.Item eventKey="Adventure">Adventure</Dropdown.Item>
  <Dropdown.Item eventKey="Comedy">Comedy</Dropdown.Item>
  <Dropdown.Item eventKey="Kids">Kids</Dropdown.Item>
  <Dropdown.Item eventKey="Crime">Crime</Dropdown.Item>
  <Dropdown.Item eventKey="Documentary">Documentary</Dropdown.Item>
  <Dropdown.Item eventKey="Drama">Drama</Dropdown.Item>
  <Dropdown.Item eventKey="Family">Family</Dropdown.Item>
  <Dropdown.Item eventKey="Fantasy">Fantasy</Dropdown.Item>
  <Dropdown.Item eventKey="Horror">Horror</Dropdown.Item>
  <Dropdown.Item eventKey="Musical">Musical</Dropdown.Item>
  <Dropdown.Item eventKey="Sci-Fi">Sci-Fi</Dropdown.Item>
  <Dropdown.Item eventKey="Thriller">Thriller</Dropdown.Item>
  <Dropdown.Item eventKey="">All Genres</Dropdown.Item>
</div>

        </DropdownButton>
      </Dropdown>
    </div>
  );
}
